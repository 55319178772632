<header class="text-gray-600 bg-white dark:bg-gray-900 body-font sticky top-0 z-50 shadow-2xl">
    <div class="container mx-auto flex flex-wrap px-2 flex-row items-center">
        <a id="header-logo" class="click-tag flex items-center mb-1 md:mx-3 mr-2" routerLink="/de">
            <img *ngIf="!darkMode" src="assets/img/logo-text.png" style="width: 92px;height: auto;"/>
            <img *ngIf="darkMode" src="assets/img/logo-text-on-dark.png" style="width: 92px;height: auto;"/>
        </a>
        <nav
            class="mr-auto md:ml-4 flex flex-wrap items-center text-base justify-center">

            <ul class="flex pt-2 -mt-2">
                <li class="hoverable hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a [routerLink]="'de/features'"
                       id="header-features"
                       class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2">
                        Features
                    </a>
                    <div class="p-0 pt-2 sm:p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-500">
                        <div class="container mx-auto w-full flex flex-wrap justify-between">
                            <!--<div class="w-full text-gray-100 dark:text-gray-100 mb-8 text-center">
                                <h2 class="font-bold text-2xl">Listen, why don’t you wait out by the speeder.</h2>
                                <p>our droids. They’ll have to wait outside. We don’t want them here.</p>
                            </div>-->
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/features'"
                                       id="header-upload"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Hochladen von Kontext-Dateien und Ergebnisexport
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Laden Sie Kontextdateien (DOC, PDF, MD, PAGES) mit OCR-Unterstützung hoch.
                                    Laden Sie die Ergebnisse Ihrer Prompts in DOC, PDF, TXT und CSV herunter.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-upload-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Etwas hochladen
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/features'"
                                       id="header-instructions"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Benutzerdefinierte Vorlagen & Batch-Generierung
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Erhalten Sie mehrere Ergebnisversionen und erstellen Sie
                                    benutzerdefinierte Vorlagen für die Erzeugung von Inhalten in spezifischen Formaten.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-instructions-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Prompts automatisieren
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'de/features'"
                                       id="header-sharable"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Teilbare und anpassbare KI-Boards
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Richten Sie für jedes Board ein anderes KI-Verhalten/eine andere Persönlichkeit ein.
                                    Teilen Sie Ihre KI-gesteuerten Boards mit Benutzern unterschiedlicher Rollen.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-blockchain-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        KI-Board erstellen
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/features'"
                                       class="click-tag"
                                       id="header-editor">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Ergebniseditor & Bearbeitungsvorlagen
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Bearbeiten Sie den Text der Promptergebnisse direkt auf Ihrem KI-Board
                                    und verwenden Sie Promptergebnisse nahtlos als Eingabe für andere Prompts.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-editor-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Text mit KI bearbeiten
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">

                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'de/features'"
                                       id="header-views"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Bilderzeugung & Magisches Bearbeiten
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Sagen Sie Lukaz mit Ihren eigenen Worten, was und wie Ihr Text bearbeiten
                                    werden soll. Erhalten Sie unglaublich realistische, KI-generierte Bilder.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-views-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Bild generieren
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-8 h-8" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'de/features'"
                                       id="header-preview"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Chat-Modus und<!-- <br/> -->
                                            Prompt-Modus
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Verbessern Sie die Fähigkeiten Ihrer Nutzer bei Prompt Engineering, während sie
                                    mit einem Board aus zwei verschiedenen Perspektiven interagieren.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-preview-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Chatten oder prompten
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'de/features'"
                                       id="header-voice"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Prompt per Sprachangabe &<!-- <br/> -->
                                            Ergebnisaudio
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Sagen Sie etwas auf Italienisch, während Sie ein deutsches Dokument als
                                    Kontext verwenden, und erhalten Sie das Ergebnis auf Englisch, wenn Sie dies anfordern.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-voice-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Mit Lukaz sprechen
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2" class="w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/features'"
                                       class="click-tag"
                                       id="header-api">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            HTML-Vorschau und<!-- <br/> -->
                                            Freier API-Zugang
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Entwickeln Sie erstklassige KI-Software oder integrieren Sie Ihr System mit
                                    unserer robusten API. Visualisieren Sie HTML-Code auf Ihrem Board.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://docs.lukaz.ai" target="_blank"
                                       id="header-api-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        API-Dokumentation
                                    </a>
                                </div>-->
                            </ul>
                        </div>
                    </div>
                </li>

                <li class="hoverable hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a [routerLink]="'de/use-cases'"
                       id="header-use-cases"
                       class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2">
                        Use Cases
                    </a>
                    <div class="p-0 pt-2 sm:p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-indigo-500">
                        <div class="container mx-auto w-full flex flex-wrap justify-between mx-2">

                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path d="M12 14l9-5-9-5-9 5 9 5z" />
                                            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'de/use-cases'"
                                       id="header-education"
                                       class="click-tag">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            KI-gesteuerte Bildung und E-Learning
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Erstellen Sie interaktive Lernboards für jedes Fach. Laden Sie Materialien hoch,
                                    die in Aufgaben wie der Erstellung von Übungen als Kontext verwendet werden können.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-education-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Unterricht generieren
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">


                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"/>
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/use-cases'"
                                       class="click-tag"
                                       id="header-support">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Kundensupport & Interaktive FAQs
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Ermöglichen Sie Ihren Kunden, Fragen zu stellen oder Prompts mit Sprachbefehlen in
                                    mehreren Sprachen zu senden, ohne die Sprache auswählen zu müssen.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-support-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        KI-gestützte FAQs erstellen
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/use-cases'"
                                       id="header-product"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Produktentwicklung und Innovation
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Treffen Sie fundierte Entscheidungen über Produktfunktionen und Verbesserungen,
                                    indem Sie das Kundenfeedback automatisieren.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-product-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        KI-Board erstellen
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">

                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'de/use-cases'"
                                       id="header-marketing"
                                       class="click-tag">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Instant Marketing und Werbung
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Erzeugen Sie sekundenschnelle Werbekampagnen und verbessern Sie die Wirksamkeit,
                                    indem Sie Ihr vorhandenes Textmaterial als Kontext verwenden.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-marketing-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Werbung generieren
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/use-cases'"
                                       class="click-tag"
                                       id="header-content">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Inhaltliche Kuratierung und Nachrichtenaggregation
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Laden Sie frühere Arbeiten hoch und erstellen Sie Inhalte in Ihrem eigenen Schreibstil.
                                    Aggregieren Sie Nachrichten- oder Blogartikel und viel mehr.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-content-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Ausarbeitung erzeugen
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor"
                                             stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                                        </svg>
                                    </div>

                                    <a [routerLink]="'de/use-cases'"
                                       id="header-research"
                                       class="click-tag">
                                        <h3
                                            class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 cursor-pointer leading-tight">
                                            Forschung & Wissensmanagement
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Stellen Sie Fragen an Ihren Quellen und verfassen Sie Forschungsarbeiten, Aufsätze
                                    oder jegliche Art von Dokumenten viel effizienter und ohne ungenaue Fakten.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-research-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Paper hochladen
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/use-cases'"
                                       class="click-tag"
                                       id="header-dev">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Softwareentwicklung und Dokumentation
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Entwickeln Sie viel schneller mit benutzerdefinierten Entwicklervorlagen,
                                    Syntaxhervorhebung und sogar Vorschau generierter Codes in HTML + TailwindCSS.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-dev-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Code generieren
                                    </a>
                                </div>-->
                            </ul>
                            <ul class="px-4 w-full sm:w-1/2 lg:w-1/4 border-indigo-300 dark:border-indigo-300 border-b sm:border-r lg:border-b-0 pb-2 sm:pb-3 pt-2 sm:pt-3">
                                <div class="flex items-center">
                                    <div
                                        class="p-2 mr-3 mb-3 sm:w-10 sm:order-none order-first sm:h-10 h-10 w-10 inline-flex items-center justify-center rounded-full bg-gray-100 dark:bg-gray-100 text-indigo-500 flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2"
                                             class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                        </svg>
                                    </div>
                                    <a [routerLink]="'de/use-cases'"
                                       class="click-tag"
                                       id="header-medical">
                                        <h3 class="font-bold text-base text-gray-100 dark:text-gray-100 text-bold mb-2 leading-tight">
                                            Medizinische Forschung und klinische Studien
                                        </h3>
                                    </a>
                                </div>
                                <p class="hidden sm:block text-gray-100 dark:text-gray-100 text-sm">
                                    Identifizieren Sie schnell mögliche Inkonsistenzen in medizinischen Berichten und
                                    erhalten Sie wertvolle Vorschläge für die potenziellen Behandlungsoptionen.
                                </p>
                                <!--<div class="hidden sm:flex items-center py-3">
                                    <svg class="h-6 pr-3 fill-current text-indigo-300 dark:text-indigo-300"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                                    </svg>
                                    <a href="https://lukaz.ai" target="_blank"
                                       id="header-medical-cta"
                                       class="click-tag text-gray-100 dark:text-gray-100 bold border-b-2 border-indigo-300 dark:border-indigo-300 hover:text-indigo-200 dark:hover:text-indigo-200">
                                        Bericht analysieren
                                    </a>
                                </div>-->
                            </ul>
                        </div>
                    </div>
                </li>
                <!--end Hoverable Link-->

                <!--end Hoverable Link-->

                <!--Regular Link-->
                <!--<li class="hidden sm:block hover:bg-indigo-500 hover:text-gray-100 dark:hover:text-gray-100">
                    <a routerLink="developers" class="click-tag relative block p-4 sm:px-4 sm:pt-4 sm:pb-2"
                       id="header-developers">
                        Developers
                    </a>
                </li>-->
            </ul>

        </nav>
        <button id="switchTheme"
                class="click-tag h-10 w-10 m-6 fixed bottom-4 right-0 justify-center items-center focus:outline-none text-gray-500"
                (click)="toggleDarkMode()" title="Switch dark/light mode">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"/>
            </svg>
        </button>
        <a href="https://lukaz.ai/sign-up" target="_blank"
           id="header-sign-up"
           class="click-tag md:inline-flex dark:text-white hidden mr-2 items-center bg-indigo-500 text-gray-100 dark:text-gray-100 dark:text-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-base mt-4 md:mt-0">
            Registrieren
        </a>

        <a href="https://lukaz.ai/sign-in" target="_blank"
           id="header-log-in"
           class="click-tag md:inline-flex hidden items-center bg-gray-700 text-gray-100 dark:text-indigo-500 border-0 py-1 px-3 focus:outline-none hover:bg-gray-800 rounded text-base mt-4 md:mt-0">
            Einloggen
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                 class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
        </a>
    </div>
    <!--
        <div class="bg-gray-200 font-sans leading-normal tracking-normal">

            <nav class="relative bg-white border-b-2 border-gray-300 text-gray-900">
                <div class="container mx-auto flex justify-between">
                    <div class="relative block p-4 lg:p-6 text-base text-indigo-200 font-bold">
                        <a href="#" class="hover:text-indigo-200 dark:hover:text-indigo-200">Logo</a>
                    </div>
                </div>
            </nav>
        </div>-->
</header>
