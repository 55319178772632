import {Injectable} from '@angular/core'

/**
 * @class       SharedUtils
 * @summary     Shared utils
 *
 * @description General shared configs, methods and services
 *              Open dialog and snack bar
 */
@Injectable()
export class SharedUtils {

    editPrivacySettings() {
        const root = document.getElementsByTagName( 'html' )[0]
        root.setAttribute( 'class', 'show--settings' )
        document.getElementById('s-cnt').setAttribute('aria-hidden', 'false')
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }
}
