import { Component, OnInit } from '@angular/core';
import {SharedUtils} from "../shared/shared.utils";

@Component({
  selector: 'tekr-footer-de',
  templateUrl: './footer-de.component.html',
  styleUrls: ['./footer-de.component.css']
})
export class FooterDeComponent implements OnInit {

  constructor(private _sharedUtils: SharedUtils) { }

  ngOnInit(): void {
  }

  editPrivacySettings() {
    this._sharedUtils.editPrivacySettings()
  }
}
