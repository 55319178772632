import { Component, OnInit } from '@angular/core';
import { SharedUtils } from '../shared/shared.utils';

@Component({
  selector: 'tekr-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private _sharedUtils: SharedUtils) {

  }

  ngOnInit(): void {
  }

  editPrivacySettings() {
    this._sharedUtils.editPrivacySettings()
  }

}
