import {NgModule} from '@angular/core';
import {BrowserModule, Meta} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {ContactComponent} from './contact/contact.component';
import {ImprintComponent} from './imprint/imprint.component';
import {ReactiveFormsModule} from '@angular/forms';
import {PipelineSectionComponent} from './pipeline-section/pipeline-section.component';
import {CareersSectionComponent} from './careers-section/careers-section.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ContactSectionComponent} from './contact-section/contact-section.component';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {FeaturesComponent} from './features/features.component';
import {UseCasesComponent} from './use-cases/use-cases.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {CookiePolicyComponent} from './cookie-policy/cookie-policy.component';
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {FeaturesDeComponent} from "./features-de/features-de.component";
import {PipelineSectionDeComponent} from "./pipeline-section-de/pipeline-section-de.component";
import {CareersSectionDeComponent} from "./careers-section-de/careers-section-de.component";
import {ContactSectionDeComponent} from "./contact-section-de/contact-section-de.component";
import {UseCasesDeComponent} from "./use-cases-de/use-cases-de.component";
import {HomeDeComponent} from "./home-de/home-de.component";
import {ContactDeComponent} from "./contact-de/contact-de.component";
import {ImprintDeComponent} from "./imprint-de/imprint-de.component";
import {HeaderDeComponent} from "./header-de/header-de.component";
import {FooterDeComponent} from "./footer-de/footer-de.component";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HomeDeComponent,
        FeaturesComponent,
        FeaturesDeComponent,
        UseCasesComponent,
        UseCasesDeComponent,
        ContactComponent,
        ContactDeComponent,
        ImprintComponent,
        ImprintDeComponent,
        PipelineSectionComponent,
        PipelineSectionDeComponent,
        CareersSectionComponent,
        CareersSectionDeComponent,
        HeaderComponent,
        HeaderDeComponent,
        FooterComponent,
        FooterDeComponent,
        ContactSectionComponent,
        ContactSectionDeComponent,
        PrivacyPolicyComponent,
        CookiePolicyComponent,
        TermsOfUseComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatMenuModule
    ],
    providers: [Meta],
    bootstrap: [AppComponent]
})
export class AppModule {
}
