
<footer class="relative text-gray-100 dark:text-gray-100 bg-indigo-500 pt-8 pb-6">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-indigo-500 bg-white dark:bg-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto px-4">
        <div class="body-font">
            <div
                class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                <div class="flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                    <a class="click-tag click-tag flex mb-0" routerLink="/"
                       id="footer-logo"
                    >
                        <img src="assets/img/logo-white.png" style="width: 100px;height: auto;"/>
                    </a>
                    <small>a <b><a href="https://tekr.io" target="_blank" class="hover:underline">tekr.io</a></b> product</small>
                </div>
                <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">PLATTFORM</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-organizations" class="click-tag hover:underline" [routerLink]="'de/features'">Funktionen</a>
                            </li>
                            <li>
                                <a id="footer-developers" class="click-tag hover:underline" [routerLink]="'de/use-cases'">Anwendungen</a>
                            </li>
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">LÖSUNGEN</h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a href="https://lukaz.ai" target="_blank"
                                    id="footer-saas" class="click-tag hover:underline">
                                    Lukaz AI
                                </a>
                            </li>
                            <li>
                                <a href="https://docs.lukaz.ai" target="_blank"
                                    id="footer-ai" class="click-tag hover:underline">
                                    Lukaz API
                                </a>
                            </li>
                            <li>
                                <a href="https://www.npmjs.com/package/@lukaz/widget" target="_blank"
                                    id="footer-blockchain" class="click-tag hover:underline">
                                    Lukaz Widget
                                </a>
                            </li>
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">
                            COMMUNITY
                        </h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a href="https://blog.lukaz.ai" target="_blank"
                                   id="footer-development" class="click-tag hover:underline">
                                    Blog
                                </a>
                            </li>
                            <li>
                                <a href="https://lukaz.ai/tutorials" target="_blank"
                                    id="footer-funding" class="click-tag hover:underline">
                                    Tutorials
                                </a>
                            </li>
                            <li>
                                <a href="https://docs.lukaz.ai" target="_blank"
                                    id="footer-consulting" class="click-tag hover:underline">
                                    API-Referenz
                                </a>
                            </li>
                            <li>
                                <a href="https://lukaz.ai/support" target="_blank"
                                    id="footer-planning" class="click-tag hover:underline">
                                    Support
                                </a>
                            </li>
                        </nav>
                    </div>
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class="title-font font-medium text-gray-100 dark:text-gray-100 tracking-widest text-sm mb-3">
                            UNTERNEHMEN
                        </h2>
                        <nav class="list-none mb-10">
                            <li>
                                <a id="footer-contact" class="click-tag hover:underline" [routerLink]="'de/contact'">Kontakt</a>
                            </li>
                            <li>
                                <a id="footer-imprint" class="click-tag hover:underline" [routerLink]="'de/imprint'">Impressum</a>
                            </li>
                            <li>
                                <a id="footer-privacy-policy" class="click-tag hover:underline" href="https://lukaz.ai/assets/docs/Datenschutzerklaerung.pdf" target="_blank">Datenschutzerklärung</a>
                            </li>
                            <li>
                                <a id="footer-cookie-policy" class="click-tag hover:underline" href="https://lukaz.ai/assets/docs/Datenschutzerklaerung.pdf" target="_blank">Cookie-Richtlinie</a>
                            </li>
                            <li>
                                <a id="footer-terms-of-use" class="click-tag hover:underline" href="https://lukaz.ai/assets/docs/Nutzungsbedingungen.pdf" target="_blank">Nutzungsbedingungen</a>
                            </li>
                            <!--<li>
                                <a id="footer-privacy-policy" class="click-tag hover:underline" [routerLink]="'de/privacy-policy'">Datenschutzbestimmungen</a>
                            </li>
                            <li>
                                <a id="footer-cookie-policy" class="click-tag hover:underline" [routerLink]="'de/cookie-policy'">Cookie-Richtlinie</a>
                            </li>
                            <li>
                                <a id="footer-terms-of-use" class="click-tag hover:underline" [routerLink]="'de/terms-of-use'">Nutzungsbedingungen</a>
                            </li>-->
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="bg-white dark:bg-gray-900">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p class="text-gray-500 text-sm text-center sm:text-left">
             <span>© 2024 Tekr GmbH</span>
<!--            <span>© 2024 tekriotech OÜ</span>-->
        </p>
        <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <a id="footer-facebook" class="click-tag text-gray-500" href="https://www.facebook.com/lukaz.io" target="_blank">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
               viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a id="footer-twitter" class="click-tag ml-3 text-gray-500" href="https://twitter.com/lukaz_ai" target="_blank">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
               viewBox="0 0 24 24">
            <path
                d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a id="footer-instagram" class="click-tag ml-3 text-gray-500" href="https://www.instagram.com/lukaz.ai" target="_blank">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
               class="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        <a id="footer-linkedin" class="click-tag ml-3 text-gray-500" href="https://www.linkedin.com/company/lukaz-ai" target="_blank">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0"
               class="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
</div>
